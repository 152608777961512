import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { i18n } from '@/plugins/i18n';

import BaseModalDialog from '@/components/BaseModalDialog.vue';
import BaseSelectDropdown from '@/components/BaseSelectDropdown.vue';
import BaseSortingDropdown from '@/components/BaseSortingDropdown.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseInputQuantity from '@/components/BaseInputQuantity.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import BaseTooltip from '@/components/BaseTooltip.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import LoadingDialog from '@/components/LoadingDialog.vue';
import BaseTable from '@/components/BaseTable.vue';
import VueCookieComply from 'vue-cookie-comply';
import LoadScript from 'vue-plugin-load-script';
import VueLazyLoad from 'vue3-lazyload';
import { VTooltip, VClosePopper } from 'floating-vue';

import App from './App.vue';
import router from './router';
import '@/styles/fonts.css';
import '@/styles/app.css';
import '@/styles/scrollbar.css';
import 'sweetalert2/dist/sweetalert2.css';
import 'vue-cookie-comply/dist/style.css';
import 'floating-vue/dist/style.css';

import { price, percentage } from '@/utils/filters';
import { clickOutside } from '@/utils/directives';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
library.add(fad);
library.add(fal);
library.add(far);
library.add(fas);
library.add(fat);
library.add(fass);

import Vuetify from '@/plugins/vuetify';

const app = createApp(App);
app.component('BaseModalDialog', BaseModalDialog);
app.component('BaseSelectDropdown', BaseSelectDropdown);
app.component('BaseSortingDropdown', BaseSortingDropdown);
app.component('BaseInput', BaseInput);
app.component('BaseInputQuantity', BaseInputQuantity);
app.component('BaseCheckbox', BaseCheckbox);
app.component('BaseBreadcrumb', Breadcrumb);
app.component('LoadingDialog', LoadingDialog);
app.component('BaseTable', BaseTable);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('BaseTooltip', BaseTooltip);


app.provide('$filters', {
  price,
  percentage,
});

//directive clickoutside
app.directive('click-outside', clickOutside);
app.use(createPinia());
app.use(i18n);
app.use(router);
app.use(VueCookieComply);
app.use(LoadScript);
app.use(VueLazyLoad, {});
app.use(Vuetify);
//directive tooltip
app.directive('tooltip', VTooltip);
app.directive('close-popper', VClosePopper);
app.mount('#app');

// Handling Global components TS types for intellisense
import { defineCustomElement } from '@vue/runtime-dom';

customElements.define('base-checkbox', defineCustomElement(BaseCheckbox));
customElements.define('base-select-dropdown', defineCustomElement(BaseSelectDropdown));
customElements.define('base-table', defineCustomElement(BaseTable));
customElements.define('base-input', defineCustomElement(BaseInput));
customElements.define('base-input-quantity', defineCustomElement(BaseInputQuantity));
customElements.define('base-tooltip', defineCustomElement(BaseTooltip));

declare module '@vue/runtime-core' {
	export interface GlobalComponents {
		'base-checkbox': typeof BaseCheckbox,
		'base-select-dropdown': typeof BaseSelectDropdown,
		'base-table': typeof BaseTable,
		'base-input': typeof BaseInput,
		'base-input-quantity': typeof BaseInputQuantity,
		'base-tooltip': typeof BaseTooltip,
	}
}