import { DESKTOP_MIN_WIDTH } from '@/constants/screen';
import { useWindowSize, useScreenOrientation } from '@vueuse/core';
import { computed } from 'vue';

/**
 * listen and return width and height of window event listener, also height in css px
 */
export const useScreenSize = () => {
  const {
    width: widthSize,
    height: heightSize,
  } = useWindowSize();
  const { orientation } = useScreenOrientation();

  const heightVhCss = computed(() => `${heightSize.value}px`);
  const isDesktop = computed(() => widthSize.value >= DESKTOP_MIN_WIDTH);
  const isTablet = computed(() => widthSize.value < DESKTOP_MIN_WIDTH);
  const isTabletHorizontal = computed(() => isTablet.value && orientation.value?.includes('landscape'));

  return {
    isTablet,
    isTabletHorizontal,
    isDesktop,
    widthSize,
    heightSize,
    heightVhCss,
  };
};