import { defineStore } from 'pinia';
import { UserService } from '@/services/UserService';
import { R6Service } from '@/services/R6Service';
import {
  UserData,
  UserUpdateData,
} from '@/utils/types/users';
// import CryptoJS from 'crypto-js';
import Cookie from 'js-cookie';
import { usePriceTypeStore } from './usePriceTypeStore';

export const useUserStore = defineStore('user', {
  state: () => ({
    loginToken: '',
    userData: {} as UserData,
    r6IsUp: true,
  }),
  actions: {
    async loginCheck(username: string, password: string) {
      const response = await UserService.login(username, password);
      if (response.status === 200 && response.data) {
        // Encrypt
        // const ciphertext = CryptoJS.AES.encrypt(`${username}_${password}`, 'zbrhash345').toString();
        // sessionStorage.setItem('isAuthen', ciphertext);
        return response;
      }
      return Promise.reject();
    },
    async logout() {
      const response = await UserService.logout();
      sessionStorage.clear();
      Cookie.remove('ping');
      return response;
    },
    async getUserData() {
      const response = await UserService.getUserData();
      if (response.status === 200) {
        this.userData = response.data;

        const priceTypeStore = usePriceTypeStore();
        const { viewPricePurchasing, viewPriceCalculationBasis, hidePrices } = response.data;
        priceTypeStore.setKbStatusFromStorage(viewPriceCalculationBasis);
        priceTypeStore.setEkStatusFromStorage(viewPricePurchasing);
        priceTypeStore.setHidePricesStatusFromStorage(hidePrices);
      }
      return response;
    },
    async updateUserData(userData: UserUpdateData) {
      const response = await UserService.updateUserData(userData);
      return response;
    },
    async updateUserPassword(oldPassword: string, newPassword: string) {
      const response = await UserService.updateUserPassword(oldPassword, newPassword);
      return response;
    },
    async resetPassword(userInput: string, countryIso: string) {
      const response = await UserService.resetPassword(userInput, countryIso);
      return response;
    },
    async checkR6IsUp() {
      const response = await R6Service.checkR6IsUp();
      if (response.status === 200) {
        this.r6IsUp = response.data;
      }
      return response;
    },
  },
});