import { defineStore } from 'pinia';
import { TyresService } from '@/services/TyresService';
import { AccessoriesService } from '@/services/AccessoriesService';
import {
  TyreBargainItem,
  TyreBarginSearch,
  TyreDiscountItem,
  TyreDiscountSearch,
  TyreAccessoryItem,
  TyreFilterOptionResponse,
  TyreItemResponse,
  TyreSortOption,
} from '@/utils/types/tyres';
import { usePromotionStore } from '@/stores/usePromotionStore';

export const useTyreStore = defineStore('tyre', {
  state: () => ({
    tyreFavoriteItems: [] as TyreItemResponse[],
    tyreFavoritePaginations: [] as TyreItemResponse[],
    tyreFavoriteCount: 0,

    tyreBargainItems: [] as TyreBargainItem[],
    tyreBargainPaginations: [] as TyreBargainItem[],
    tyreBargainCount: 0,

    tyreDiscountItems: [] as TyreDiscountItem[],
    tyreDiscountPaginations: [] as TyreDiscountItem[],
    tyreDiscountCount: 0,

    filterTyreBargainFilterOptions: null as TyreFilterOptionResponse | null,
    filterTyreDiscountFilterOptions: null as TyreFilterOptionResponse | null,
  }),
  actions: {
    async getTyresFavoriteFirstPage(search?: {
      page: number,
      limit?: number,
      sort?: TyreSortOption[],
      filter?: {
        tyreSize?: string[],
        manufacturer?: {
          key: string,
          name: string,
        }[],
        useType?: {
          key: string,
          name: string,
        }[],
      }
    }) {
      const response = search
        ? await TyresService.getTyresFavorites(search)
        : await TyresService.getTyresFavorites({
          page: 1,
        });
      if (response.status === 200) {
        this.tyreFavoriteItems = response.data.items;
        this.tyreFavoritePaginations = response.data.items;
        this.tyreFavoriteCount = response.data.fullCount;

        const promotionStore = usePromotionStore();
        promotionStore.setPromotionData(response.data.promotions);
      }
      return response;
    },
    async getTyresFavoritePagination(search: {
      page: number,
      limit?: number,
      sort?: TyreSortOption[],
      filter?: {
        tyreSize?: string[],
        manufacturer?: {
          key: string,
          name: string,
        }[],
        useType?: {
          key: string,
          name: string,
        }[],
      }
    }) {
      const response = await TyresService.getTyresFavorites(search);
      if (response.status === 200 && response.data.items.length === 0) {
        return Promise.reject('noresult');
      }
      if (response.status === 200) {
        this.tyreFavoritePaginations = [
          ...this.tyreFavoritePaginations,
          ...response.data.items,
        ];
        const promotionStore = usePromotionStore();
        promotionStore.setPromotionData(response.data.promotions);
      }
      return response;
    },
    updateTyreFavoriteItems(updateItem: TyreItemResponse) {
      this.tyreFavoriteItems = this.tyreFavoriteItems.slice().filter((item) => {
        if (item.articleNo === updateItem.articleNo
          && item.articleNoR6 === item.articleNoR6
        ) {
          return !item.favorite;
        } else {
          return true;
        }
      });
      this.tyreFavoritePaginations = this.tyreFavoritePaginations.slice().filter((item) => {
        if (item.articleNo === updateItem.articleNo && item.articleNoR6 === item.articleNoR6) {
          return !item.favorite;
        } else {
          return true;
        }
      });
    },
    updateTyreFavoriteWholeItems(items: TyreItemResponse[]) {
      this.tyreFavoriteItems = [...items];
    },
    updateTyreFavoritePaginationWholeItems(items: TyreItemResponse[]) {
      this.tyreFavoritePaginations = [...items];
    },
    async getTyresBargainFirstPage(searchPayload: TyreBarginSearch) {
      const response = await TyresService.getTyresBargain(searchPayload);
      if (response.status === 200) {
        this.tyreBargainItems = response.data.items;
        this.tyreBargainPaginations = response.data.items;
        this.tyreBargainCount =  response.data.fullCount;

        const promotionStore = usePromotionStore();
        promotionStore.setPromotionData(response.data.promotions);

        this.filterTyreBargainFilterOptions = {...response.data.filter};
      }
      return response;
    },
    async getTyresBargainPagination(searchPayload: TyreBarginSearch) {
      const response = await TyresService.getTyresBargain(searchPayload);
      if (response.status === 200 && response.data.items.length === 0) {
        return Promise.reject('noresult');
      }
      if (response.status === 200) {
        this.tyreBargainPaginations = [
          ...this.tyreBargainPaginations,
          ...response.data.items,
        ];
        const promotionStore = usePromotionStore();
        promotionStore.setPromotionData(response.data.promotions);
      }
      return response;
    },
    async getTyresDiscountFirstPage(searchPayload: TyreDiscountSearch) {
      const response = await TyresService.getTyresDiscount(searchPayload);
      if (response.status === 200) {
        this.tyreDiscountItems = response.data.items;
        this.tyreDiscountPaginations = response.data.items;
        this.tyreDiscountCount =  response.data.fullCount;

        const promotionStore = usePromotionStore();
        promotionStore.setPromotionData(response.data.promotions);

        this.filterTyreDiscountFilterOptions = {...response.data.filter};
      }
      return response;
    },
    async getTyresDiscountPagination(searchPayload: TyreDiscountSearch) {
      const response = await TyresService.getTyresDiscount(searchPayload);
      if (response.status === 200 && response.data.items.length === 0) {
        return Promise.reject('noresult');
      }
      if (response.status === 200) {
        this.tyreDiscountPaginations = [
          ...this.tyreDiscountPaginations,
          ...response.data.items,
        ];
        const promotionStore = usePromotionStore();
        promotionStore.setPromotionData(response.data.promotions);
      }
      return response;
    },
    async getTyreAccessories(articleNo: string): Promise<TyreAccessoryItem[]> {
      // Will fix
      const response = await AccessoriesService.getAccessoriesByTyre(articleNo);
      if (response.status === 200) {
        return response.data.items;
      }
      return Promise.reject();
    },
  },
});