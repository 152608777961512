import { defineStore } from 'pinia';
import { DashboardService } from '@/services/DashboardService';
import { Promotion } from '@/utils/types/promotion';

export const usePromotionStore = defineStore('promotionPopup', {
  state: () => ({
    promotionData: [] as Promotion[],
    showPromotionDialog: false,
  }),
  actions: {
    setPromotionData(data: Promotion[]) {
      if (data.length === 0) {
        this.promotionData = [];
        this.showPromotionDialog = false;
        return;
      }

      this.promotionData = data.slice().filter((item) => !item.blockModal);
      if (this.promotionData.length > 0) {
        this.showPromotionDialog = true;
      }
    },
    clearPromotionData() {
      this.promotionData = [];
    },
    async blockPromotion(id: string) {
      const response = await DashboardService.blockPromotion(id);
      return response;
    },
    closePromotionDialog() {
      this.showPromotionDialog = false;
    },
  },
});