import { apiClient } from '@/services';
import {
  TyreSearchByBikeModel,
  TyreBarginSearch,
  TyreDiscountSearch,
  SearchTyreParam,
  SearchArticleTyreParam,
  TyreSortOption,
} from '@/utils/types/tyres';

export const TyresService = {
  getTyresSearch(search: {
    front?: SearchTyreParam,
    page?: string | number,
    rear?: SearchTyreParam,
    limit?: string | number,
    sort?: TyreSortOption[],
    filter?: {
      stock?: string | boolean,
    },
  }) {
    return apiClient.get('/tyres/search', {
      params: {
        search: {
          ...search,
          sort: search.sort
            ? search.sort
            : [{
              column: 'manufacturer',
              type: 'asc',
            }],
          limit: search.limit
            ? search.limit
            : 50,
        },
      },
    });
  },
  getTyresSearchArticle(search: {
    front?: SearchArticleTyreParam,
    page?: string | number,
    rear?: SearchArticleTyreParam,
    limit?: string | number,
    sort?: TyreSortOption[],
    filter?: {
      stock?: string | boolean,
    },
  }) {
    return apiClient.get('/tyres/matchcode', {
      params: {
        search: {
          ...search,
          sort: search.sort
            ? search.sort
            : [{
              column: 'manufacturer',
              type: 'asc',
            }],
          limit: search.limit
            ? search.limit
            : 50,
        },
      },
    });
  },
  getTyresSearchMotocross(search: {
    front?: SearchArticleTyreParam,
    page?: string | number,
    rear?: SearchArticleTyreParam,
    limit?: string | number,
    sort?: TyreSortOption[],
    filter?: {
      stock?: string | boolean,
    },
  }) {
    return apiClient.get('/tyres/motocross', {
      params: {
        search: {
          ...search,
          sort: search.sort
            ? search.sort
            : [{
              column: 'manufacturer',
              type: 'asc',
            }],
          limit: search.limit
            ? search.limit
            : 50,
        },
      },
    });
  },
  getTyresFavorites(search: {
    page: number,
    limit?: number,
    sort?: TyreSortOption[],
    filter?: {
      tyreSize?: string[],
      manufacturer?: {
        key: string,
        name: string,
      }[],
      useType?: {
        key: string,
        name: string,
      }[],
    }
  }) {
    return apiClient.get('/tyres/favorites', {
      params: {
        search: {
          ...search,
          sort: search.sort
            ? search.sort
            : [{
              column: 'manufacturer',
              type: 'asc',
            }],
          limit: search.limit
            ? search.limit
            : 50,
        },
      },
    });
  },
  getTyreSearchOptions(params?: SearchTyreParam) {
    if (params) {
      return apiClient.get('/tyres/search-values', {
        params,
      });
    }
    return apiClient.get('/tyres/search-values');
  },
  getTyreRoadSurfaces() {
    return apiClient.get('/tyres/road-surfaces');
  },
  getTyresByBikeModel(search: TyreSearchByBikeModel) {
    return apiClient.get('/tyres/bike-model', {
      params: {
        search: {
          ...search,
          limit: search.limit
            ? search.limit
            : 50,
        },
      },
    });
  },
  getTyresBargain(search: TyreBarginSearch) {
    return apiClient.get('/tyres/bargain', {
      params: {
        search: {
          ...search,
          limit: search.limit
            ? search.limit
            : 50,
        },
      },
    });
  },
  getTyresDiscount(search: TyreDiscountSearch) {
    return apiClient.get('/tyres/discount', {
      params: {
        search: {
          ...search,
          limit: search.limit
            ? search.limit
            : 50,
        },
      },
    });
  },
  changeTyreFavoriteState(articleNo: string) {
    return apiClient.put('/tyres/change-favorite-state', {}, {
      params: {
        articleNo,
      },
    });
  },
};