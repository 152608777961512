import axios from 'axios';
import { i18n } from '@/plugins/i18n';
import CryptoJS from 'crypto-js';
import { UserService } from '@/services/UserService';

export const BASE_API_URL = '/api';
export const apiClient = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('error', error.response);
    if (!error.response) {
      return Promise.reject(error);
    }
    const youAreNotLogin500 = error.response.status === 500
      && error.response.data
      && error.response.data.detail === 'You are not logged in.';
    if (error.response.status === 401 || youAreNotLogin500) {
      window.location.href = 'https://zbr-hohl.de/';
      // const ciphertext = sessionStorage.getItem('isAuthen');
      // if (!ciphertext) {
      //   sessionStorage.clear();
      //   const { locale } = i18n.global;
      //   window.location.href = `/${locale.value.split('-')[0]}/login`;
      //   return;
      // }
      // // Decrypt
      // const bytes  = CryptoJS.AES.decrypt(ciphertext, 'zbrhash345');
      // const originalText = bytes.toString(CryptoJS.enc.Utf8);
      // const un = originalText.split('_')[0];
      // const pw = originalText.split('_')[1];
      // UserService.login(un, pw).finally(() => {
      //   window.location.reload();
      // });
    }
    return Promise.reject(error);
  },
);

export const apiFormData = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  transformRequest: [
    (data, headers) => {
      // Do whatever you want to transform the data
      const formData = new FormData();
      for (const key in data) {
        if (data[key] || data[key] === 0) {
          const value = data[key];
          formData.append(key, value);
        }
      }
      return formData;
    },
  ],
});

apiFormData.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (!error.response) {
      return Promise.reject(error);
    }
    const youAreNotLogin500 = error.response.status === 500
      && error.response.data
      && error.response.data.detail === 'You are not logged in.';
    if (error.response.status === 401 || youAreNotLogin500) {
      window.location.href = 'https://zbr-hohl.de/';
    //   const ciphertext = sessionStorage.getItem('isAuthen');
    //   if (!ciphertext) {
    //     sessionStorage.clear();
    //     const { locale } = i18n.global;
    //     window.location.href = `/${locale.value.split('-')[0]}/login`;
    //     return;
    //   }
    //   // Decrypt
    //   const bytes  = CryptoJS.AES.decrypt(ciphertext, 'zbrhash345');
    //   const originalText = bytes.toString(CryptoJS.enc.Utf8);
    //   const un = originalText.split('_')[0];
    //   const pw = originalText.split('_')[1];
    //   UserService.login(un, pw).finally(() => {
    //     window.location.reload();
    //   });
    }
    return Promise.reject(error);
  },
);