import { defineStore } from 'pinia';

interface StringMap { [key: string]: boolean }

export const useDialogStore = defineStore('dialog', {
  state: () => ({
    showDialog: {} as StringMap,
  }),
  actions: {
    triggerShowDialog(key: string) {
      this.showDialog = {
        ...this.showDialog,
        [key]: !this.showDialog[key],
      };
    },
    setShowDialog(key: string, status: boolean) {
      this.showDialog = {
        ...this.showDialog,
        [key]: status,
      };
    },
  },
});