export interface VueFilters {
  price: (value: any, currency?: string) => string,
  percentage: (value: any) => string,
}

export function price(value: any, currency = '€') {
  if (value === null || value === undefined) {
    return '-';
  }

  const intValue = parseFloat(value);
  if (intValue === 0) {
    return `${currency} ${intValue
      .toFixed(2)
      .toLocaleString()
      .replace('.', ',')}`;
  }

  if (!intValue) {
    return `${currency} -`;
  }

  return `${currency} ${intValue
    .toFixed(2)
    .toLocaleString()
    .replace('.', ',')}`;
}

export function percentage(value: any) {
  if (value === null || value === undefined) {
    return '-';
  }

  const intValue = parseFloat(value);
  if (intValue === 0) {
    return `${intValue
      .toFixed(2)
      .toLocaleString()
      .replace('.', ',')}%`;
  }

  if (!intValue) {
    return '- %';
  }

  return `${intValue
    .toFixed(2)
    .toLocaleString()
    .replace('.', ',')}%`;
}