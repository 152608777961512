import { i18n } from '@/plugins/i18n';
import { BASE_API_URL } from '@/services';
import { StringKeyValue } from '@/utils/types';
import { PossibleLang, LangOption } from '@/utils/types/translation';
import { ref } from 'vue';

const translationData = ref({} as LangOption);

function saveTranslationData(lang: string, data: StringKeyValue) {
  translationData.value = {
    ...translationData.value,
    [lang]: data,
  };
}

export const useTranslation = () => {
  /**
    * Async loads a translation from API using fetch API
    * @param lang
    * @return {Promise<*>|*}
    */
  function loadLanguageAsync(lang: string) {
    let localeFormat = '';
    if (lang === 'en') {
      localeFormat = 'en';
    } else if (lang === 'fr') {
      localeFormat = 'fr';
    } else if (lang === 'nl') {
      localeFormat = 'nl';
    } else {
      localeFormat = 'de';
    }
    return fetch(`${BASE_API_URL}/translation/?language=${localeFormat}`)
      .then((response) => response.json())
      .then((json) => json)
      .catch((err) => {
        console.error(
          `Can't get messages from /${lang}/translation: ${err.message}`,
        );
        return {};
      });
  }

  /**
   * Loads new translation messages and changes the language when finished
   * @param lang
   * @return {Promise<any>}
   */
  function changeLanguage(lang: string) {
    const possibleLang = lang as PossibleLang;
    if (translationData.value[possibleLang]) {
      i18n.global.setLocaleMessage(lang, {...translationData.value[possibleLang]});
      i18n.global.locale.value = lang;
      document.documentElement.lang = lang;
      return Promise.resolve(lang); // has been loaded prior
    }

    return loadLanguageAsync(lang).then((translationData) => {
      const messages = translationData[lang];
      saveTranslationData(lang, messages);
      i18n.global.setLocaleMessage(lang, {...messages});
      i18n.global.locale.value = lang;
      document.documentElement.lang = lang;
    });
  }

  /**
   * Checks if the route's param is supported, if not, redirects to the first supported one.
   * @param {VueRouter} to
   * @param {VueRouter} from
   * @param {VueRouterFunction} next
   * @return {*}
   */
  function routeMiddleware(locale: string) {
    // Load async message files here
    // const lang = to.params.lang.toString();
    // i18n.global.locale.value = lang;
    return changeLanguage(locale);
  }

  /**
   * Returns a new route object that has the current language already defined
   * To be used on pages and components, outside of the main \ route, like on Headers and Footers.
   * @example <router-link :to="$i18nRoute({ name: 'someRoute'})">Click Me </router-link>
   * @param {Object} to - route object to construct
   */
  // function i18nRoute(to: RouteLocationNormalized) {
  //   return {
  //     ...to,
  //     params: { ...to.params, lang: i18n.global.locale.value },
  //   };
  // }

  return {
    changeLanguage,
    routeMiddleware,
    // i18nRoute,
  };
};