import { defineStore } from 'pinia';
import { UserService } from '@/services/UserService';

export const usePriceTypeStore = defineStore('priceType', {
  state: () => ({
    storeKbStatus: null as boolean | null,
    storeEkStatus: null as boolean | null,
    storeHidePricesStatus: null as boolean | null,
  }),
  actions: {
    async updateKb(status: boolean) {
      this.storeKbStatus = status;
      const response = await UserService.updateUserSettings({ viewPriceCalculationBasis: status });
      if (response.status === 200) {
        sessionStorage.setItem('KbStatus', status.toString());
      }
      return response;
    },
    async updateEk(status: boolean) {
      this.storeEkStatus = status;
      const response = await UserService.updateUserSettings({ viewPricePurchasing: status });
      if (response.status === 200) {
        sessionStorage.setItem('EkStatus', status.toString());
      }
      return response;
    },
    setKbStatusFromStorage(status: boolean) {
      this.storeKbStatus = status;
    },
    setEkStatusFromStorage(status: boolean) {
      this.storeEkStatus = status;
    },
    setHidePricesStatusFromStorage(status: boolean) {
      this.storeHidePricesStatus = status;
    },
  },
});