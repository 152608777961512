import { apiClient } from '@/services';
import { BikeManufacturerItem } from '@/utils/types/bikes';

export const BikesService = {
  getManufacturers() {
    return apiClient.get('/bikes/manufacturers');
  },
  getCapacities() {
    return apiClient.get('/bikes/engine-capacitys');
  },
  getModelsManufacturer(manufacturer: string, ccmFrom: number, ccmTo: number) {
    return apiClient.get('/bikes/models/manufacturer', {
      params: {
        manufacturer,
        ccmFrom,
        ccmTo,
      },
    });
  },
  getTypes() {
    return apiClient.get('/bikes/types');
  },
  getModelsByTyreArticleNo(articleNo: string) {
    return apiClient.get('/bikes/models/tyre', {
      params: {
        articleNo,
      },
    });
  },
  getModelsByBikeType(type: string) {
    return apiClient.get('/bikes/models/type', {
      params: {
        type,
      },
    });
  },

  getBikesSearchHistory() {
    return apiClient.get('/bikes/recently-searched?limit=20');
  },

  changeBikeManufacturerFavoriteState({ isFavorite, key, name }: BikeManufacturerItem) {
    return apiClient.put('/bikes/manufacturers/change-favorite-state', {
      isFavorite,
      key,
      name,
    });
  },

  getModelsByHsnTsnVsn(hsn: string, tsn: string, vsn: string) {
    return apiClient.get(`/bikes/models/german-code/${hsn}/${tsn}/${vsn}`);
  },
};