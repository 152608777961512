import { apiClient } from '@/services';
import { CalculationItem, GlobalCalculationSetting, CalculationSetting } from '@/utils/types/calculations';

export const CalculationService = {
  getCalculations() {
    return apiClient.get('/calculation/');
  },
  createNewCalculation(calculation: CalculationItem) {
    return apiClient.post('/calculation/level/new', calculation);
  },
  removeCalculation(calculationId: number) {
    return apiClient.delete('/calculation/level/delete', {
      params: {
        id: calculationId,
      },
    });
  },
  updateCalculationGlobalSetting(calculationSetting: GlobalCalculationSetting) {
    return apiClient.put('/calculation/update/globals', calculationSetting);
  },
  updateCalculation(calculationSetting: CalculationSetting) {
    return apiClient.put('/calculation/update', calculationSetting);
  },
};