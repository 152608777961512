import { apiClient } from '@/services';
import {
  UserUpdateData,
  ChangeUserSettingsData,
  UserUpdateSetting,
} from '@/utils/types/users';

export const UserService = {
  login(username: string, password: string) {
    const params = new URLSearchParams({
      username,
      password,
    });
    return apiClient.post('/user/login', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  logout() {
    return apiClient.get('/user/logout');
  },
  getUserData() {
    return apiClient.get('/user/data');
  },
  updateUserData(userData: UserUpdateData) {
    return apiClient.put('/user/data/update', userData);
  },
  updateUserPassword(oldPassword: string, newPassword: string) {
    return apiClient.put('/user/change-password', {}, {
      params: {
        old: oldPassword,
        new: newPassword,
      },
    });
  },
  pingSession() {
    return apiClient.get('/user/ping');
  },
  changeUserSettings(payload: ChangeUserSettingsData) {
    return apiClient.patch('/user/change/settings', payload);
  },
  updateUserSettings(payload: UserUpdateSetting) {
    return apiClient.patch('/user/change/settings', payload);
  },
  resetPassword(userInput: string, countryIso: string) {
    return apiClient.get(`/user/password/forgotten/${countryIso}/${userInput}`);
  },
};