import { apiClient } from '@/services';
import { AccessorySearchKey } from '@/utils/types/accessories';

export const AccessoriesService = {
  getAccessoriesByTyre(articleNo: string) {
    return apiClient.get('/accessories/tyre', {
      params: {
        articleNo,
      },
    });
  },
  getAccessoriesGroups() {
    return apiClient.get('/accessories/groups');
  },
  getAccessoriesSearch(searchKey: AccessorySearchKey) {
    return apiClient.get('/accessories/search', {
      params: searchKey,
    });
  },
  getAccesoryFilterValue() {
    return apiClient.get('/accessories/search-values');
  },
};