import { defineStore } from 'pinia';
import { PdfService } from '@/services/PdfService';
import { PdfTerms } from '@/utils/types/pdf';

export const usePdfStore = defineStore('pdfStore', {
  state: () => ({
    pdfTerms: {} as PdfTerms,
  }),
  actions: {
    async getPdfTerms() {
      const response = await PdfService.getPdfTerms();
      if (response.status === 200) {
        if (response.data && response.data.length > 0) {
          this.pdfTerms = response.data[0];
        }
      }

      return response;
    },
  },
});