import { apiClient } from '@/services';

export const DashboardService = {
  getShortNews(isoCode: string) {
    return apiClient.get('/dashboard/short-news', {
      params: {
        countryCode: isoCode,
      },
    });
  },
  getJournal(isoCode: string) {
    return apiClient.get('/dashboard/journal', {
      params: {
        countryCode: isoCode,
      },
    });
  },
  blockPromotion(id: string) {
    return apiClient.post(`/dashboard/block/promotion/${id}`);
  },
};
