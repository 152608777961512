import { createI18n } from 'vue-i18n';

const locale = window.location.pathname.replace(/^\/([^/]+).*/i,'$1');

export const i18n = createI18n({
  // something vue-i18n options here ...
  legacy: false,
  locale: (locale.trim().length && locale != '/') ? locale : 'de',
  fallbackLocale: 'de',
  silentTranslationWarn: true,
  globalInjection: true,
  fallbackWarn: false,
  missingWarn: false,
  warnHtmlInMessage: false,
});
