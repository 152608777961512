import { defineStore } from 'pinia';
import { CalculationService } from '@/services/CalculationService';
import { CalculationItem, GlobalCalculationSetting, CalculationSetting } from '@/utils/types/calculations';

export const useCalculationStore = defineStore('calculation', {
  state: () => ({
    taxValue: 0,
    isCalculationTax: false,
    roundingDigits: 0,
    calculationData: [] as CalculationItem[],
  }),
  actions: {
    async getCalculations() {
      const response = await CalculationService.getCalculations();
      if (response.status === 200) {
        this.taxValue = response.data.tax;
        this.isCalculationTax = response.data.calcTax;
        this.roundingDigits = response.data.roundingDigits;
        this.calculationData = response.data.levels;
      }
      return response;
    },
    async createNewCalculation(calculation: CalculationItem) {
      const response = await CalculationService.createNewCalculation(calculation);
      return response;
    },
    async removeCalculation(calculationId: number) {
      const response = await CalculationService.removeCalculation(calculationId);
      return response;
    },
    async updateCalculationGlobalSetting(calculationSetting: GlobalCalculationSetting) {
      const response = await CalculationService.updateCalculationGlobalSetting(calculationSetting);
      return response;
    },
    async updateCalculation(calculationSetting: CalculationSetting) {
      const response = await CalculationService.updateCalculation(calculationSetting);
      return response;
    },
  },
});