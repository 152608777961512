import { TyreBikeModelAxisItem } from '@/utils/types/tyres';
/**
 * get current layout name from router meta
 * */
export const useVehicleResultHelper = () => {
  //  No tire data/information available and articleNo: "AA"
  function isNoInfoAndArticleAA(item: TyreBikeModelAxisItem | any) {
    if (!item) {
      return false;
    }
    return item.description === '' && item.articleNo === 'AA';
  }

  //  No tire data/information available and articleNo: is not "AA" and articleNo: "8888888888"
  function isNoInfoAndArticle88(item: TyreBikeModelAxisItem | any) {
    if (!item) {
      return false;
    }
    return item.description === '' && item.articleNo === '8888888888';
  }
  function isArticle88(item: TyreBikeModelAxisItem | any) {
    if (!item) {
      return false;
    }
    return item.articleNo === '8888888888';
  }
  function isArticle99(item: TyreBikeModelAxisItem | any) {
    if (!item) {
      return false;
    }
    return item.articleNo === '9999999999';
  }
  function isCommentNoProduction(item: TyreBikeModelAxisItem | any) {
    if (!item) {
      return false;
    }
    return item.comments && item.comments.includes('TOOLS-NO-PRODUCTION-2022');
  }
  function isOnlyOrderByPhone(item: TyreBikeModelAxisItem | any) {
    if (!item) {
      return false;
    }
    return item.specialMarking === '15';
  }
  function hasTooltipLimitedAmount(item: TyreBikeModelAxisItem | any) {
    if (!item) {
      return false;
    }
    return item.saleGroup === 3 || item.saleGroup === 6;
  }

  return {
    isNoInfoAndArticleAA,
    isNoInfoAndArticle88,
    isArticle88,
    isArticle99,
    isCommentNoProduction,
    isOnlyOrderByPhone,
    hasTooltipLimitedAmount,
  };
};

